<template>
  <div v-loading="loading">
    <PageTitle
      title="自定義色系主題"
      hideBtn
      icon="chevron_left"
      @iconClick="$router.push({name: 'MemberCenterColorThemeSetting'})"
    />

    <section v-loading="loading" class="side-highlight-container">
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="色系主題名稱" prop="name">
          <BaseElInput v-model="formData.name" :disabled="isSystemStyle" />
        </BaseElFormItem>
        <el-divider />

        <BaseElFormItem label="顏色定義">
          <div class="flex flex-col">
            <div class="flex items-center" style="gap: 20px">
              <p class="flex-shrink-0" style="width: 150px">主色（Primary）</p>
              <ColorPicker
                v-if="formData.primaryColor"
                :value.sync="formData.primaryColor"
                :disabled="isSystemStyle"
                @change="onColorChange"
              />
            </div>
            <div class="flex items-center" style="gap: 20px">
              <p class="flex-shrink-0" style="width: 150px">副色（Secondary）</p>
              <ColorPicker
                v-if="formData.secondaryColor"
                :value.sync="formData.secondaryColor"
                :disabled="isSystemStyle"
                @change="onColorChange"
              />
            </div>
          </div>
        </BaseElFormItem>
      </BaseElForm>
      <section>
        <p>色系預覽</p>
        <div class="preview-area">
          <MemberCenterColorThemePreview
            :primary="formData.primaryColor"
            :secondary="formData.secondaryColor"
          />
        </div>
      </section>
    </section>

    <PageFixedFooter
      :confirmLoading="loading"
      :disabledConfirm="isSystemStyle"
      @cancel="$router.push({name: 'MemberCenterColorThemeSetting'})"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberCenterColorThemePreview from './MemberCenterColorThemePreview.vue'
import { get, find } from 'lodash'
import { useRoute, useRouter } from 'vue-router/composables'
import { simpleSyncFormData, useBaseForm } from '@/use/useForm'
import ColorPicker from '@/components/ColorPicker.vue'
import { noEmptyRules } from '@/validation'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { UpdateMemberCenterColorTheme, CreateMemberCenterColorTheme, FindMemberCenterColorTheme } from '@/api/memberCenterColorTheme'
import { systemMemberCenterColorThemesTemplate } from '@/config/memberCenter'

export default defineComponent({
  name: 'ParentComponent',
  components: { PageFixedFooter, PageTitle, ColorPicker, MemberCenterColorThemePreview },
  // emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const { initFormData, formData, formRef, loading, formSubmit } = useBaseForm()
    const usingOpacity = ref(false)

    const targetId = computed(() => get(route.params, 'id'))
    const styleName = computed(() => route.params.styleName || 'classic')
    const isSystemStyle = computed(() => {
      if (!targetId.value) return false
      if (targetId.value.includes('@system-')) return true
      return false
    })

    initFormData({
      name: null,
      primaryColor: '',
      secondaryColor: '',
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
      }

      // handle custom rules here
      // if (...) rules.xxx = [...]

      return rules
    })

    // 需要時再使用：
    // onFormRulesChangeClearValidate(formRef, formRules)

    const submitData = computed(() => {
      return {
        shopId: shopId.value,
        name: formData.name,
        id: targetId.value,
        primaryColor: formData.primaryColor,
        secondaryColor: formData.secondaryColor,
      }
    })

    // 同步表單資料
    const syncFormData = (data) => {
      simpleSyncFormData(data, formData)
    }

    const onColorChange = (color) => {
      if (color.a < 1) usingOpacity.value = true
      else usingOpacity.value = false
    }

    const onCancel = () => {
      router.push({
        name: 'PageName',
      })
    }

    // 送出表單
    const onSubmit = async () => {
      if (usingOpacity.value) {
        window.$message.warning('顏色不可設置透明度，請重新設定。')
        return
      }
      const handler = async (data, { emit, loading }) => {
        let apiMethod
        let successMsg
        if (!targetId.value) {
          apiMethod = CreateMemberCenterColorTheme
          successMsg = '新增成功！'
        }
        if (targetId.value) {
          apiMethod = UpdateMemberCenterColorTheme
          successMsg = '更新成功！'
        }
        const [, err, rawErr] = await apiMethod(data)
        loading.value = false
        if (err) {
          console.log('rawErr', rawErr)
          window.$message.error(err)
          return
        }
        window.$message.success(successMsg)

        router.push({
          name: 'MemberCenterColorThemeSetting',
        })
        // emit('refresh')
        // emit('close')
      }
      await formSubmit(handler, submitData.value, { emit, loading })
    }

    onMounted(async () => {
      loading.value = true
      if (!isSystemStyle.value && route.params.styleName) {
        await simpleFetch(FindMemberCenterColorTheme, { shopId: shopId.value, id: targetId.value },
          (res) => syncFormData(res),
        )
      } else if (isSystemStyle.value) {
        const styleTemplate = find(systemMemberCenterColorThemesTemplate, { name: styleName.value })
        formData.name = styleName.value
        formData.primaryColor = styleTemplate.primaryColor
        formData.secondaryColor = styleTemplate.secondaryColor
      } else {
        formData.primaryColor = '#31405f'
        formData.secondaryColor = '#B69671'
      }
      loading.value = false
    })

    return {
      loading,
      formRef,
      formData,
      formRules,
      onCancel,
      onSubmit,
      isSystemStyle,
      onColorChange,
    }
  },
})
</script>

<style scoped lang="postcss">

.preview-area {
  @apply bg-[#F5F7FA] rounded-[4px] py-[60px] px-[50px] w-[fit-content];
}
</style>
